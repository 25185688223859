module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"auslandsjahr.org","short_name":"auslandsjahr.org","start_url":"/","background_color":"#ffffff","theme_color":"#e96101","display":"standalone","icon":"src/images/auslandsjahr-emblem.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e73e6d0da2ee5c61410b5eec53642c5e"},
    },{
      plugin: require('../node_modules/gatsby-source-akcms-v2/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"3d1eaa33925e4a1cf7ac6d5b4e7d7dd7423f5ed05756360b","apiBaseUrl":"https://api.cms.austauschkompass.de/api/v1"},
    },{
      plugin: require('../node_modules/gatsby-source-akratings-high-school/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://www.schueleraustausch.de/api/v2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-K0ZDS90ZF3"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"13","matomoUrl":"https://stats.schueleraustausch.net","siteUrl":"https://www.auslandsjahr.org"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
